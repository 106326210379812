import { useState, useEffect, React } from 'react';
import { Activity, MessageCircle, AlertCircle, Users, Inbox , FileText, Clipboard, ChevronDown, ChevronRight, BarChart2, Zap, RefreshCw, Circle, Check, Flag, AlertTriangle, User, Printer, ThumbsUp, ThumbsDown } from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import Logo from './Logo';

// Add this constant data near the top of your EmailAnalyzer component
// const DUMMY_ANALYSIS_DATA = [
//   {
//     _id: '6740be3e3292489c1c7aa234',
//     user_id: '6740be323292489c1c7aa233',
//     content: 'Note: If a student previously did NSO credits/units, then they should...',
//     initial_result: {
//       id: '20241122172402',
//       timestamp: 'Fri, 22 Nov 2024 17:24:02 +0000',
//       sender_name: 'user@example.com',
//       recipient_name: 'recipient@example.com',
//       content: 'Note: If a student previously did NSO credits/units, then they should...',
//       subject: 'User Input',
//       'Non-toxic': 1,
//       toxic: {
//         Impolite: {
//           Sarcasm: 0,
//           Stereotype: 0,
//           'Forced teaming': 0,
//           Authoritarian: 0,
//           Rude: 0,
//           'Lack of empathy': 0
//         },
//         'Negative Gossip': {
//           Mocking: 0,
//           Complain: 0
//         },
//         Offensive: {
//           Profanity: 0,
//           Discrimination: 0,
//           Bullying: 0,
//           Violence: 0,
//           Harassment: 0
//         },
//         'Passive-aggressive': 0,
//         Condescending: 0,
//         Microaggression: 0,
//         'Implicit bias': 0,
//         Other: 0
//       },
//       explanation: 'The email is clearly focused on registration guidelines for students regarding NSO and NSS credits. The tone is professional and informative, providing necessary instructions without any toxic elements.',
//       harmful_phrases: [],
//       relationship_analysis: {
//         relationship_analysis: {
//           professional_cordial: 1,
//           professional_neutral: 0,
//           professional_toxic: 0,
//           playful_banter: 0,
//           Collegial: 0,
//           Mentor_Mentee: 0,
//           Cross_Functional_Partnerships: 0,
//           Too_personal: 0
//         }
//       },
//       toxicity_analysis: {
//         overall_score: 6,
//         overall_assessment: 'The email is professionally adequate, but could benefit from improved clarity and structure in conveying the registration instructions.',
//         category_scores: {
//           Professional_Communication: {
//             score: 6,
//             evidence: ['Clear registration guidelines', 'Professional tone'],
//             impact_analysis: 'The email effectively communicates essential information while maintaining professionalism.'
//           }
//         }
//       },
//       reframed_version: {
//         subject: 'Registration Guidance for NSO and NSS',
//         content: 'Dear Students,\n\nPlease note the following registration guidelines:\n\n1. Students who have previously completed NSO credits/units should...'
//       }
//     },
//     timestamp: '2024-11-22T17:24:14.564938',
//     feedback_results: [],
//     reactions: {
//       likes: [],
//       dislikes: []
//     },
//     status: 'completed'
//   }
// ];

// You can use this in your component:
// const [analysis, setAnalysis] = useState(DUMMY_ANALYSIS_DATA[0].initial_result);
// Add this constant data near the top of your EmailAnalyzer component
// const DUMMY_ANALYSIS_DATA = [
//   {
//     timestamp: "2024-11-27T20:23:34.883113",
//     feedback_results: [],
//     reactions: {},
//     status: "completed",
//     _id: '674d1266667fede7a2a35974',
//     user_id: "674d1259667fede7a2a35973",
//     content: "you are not nice.",
//     initial_result: {
//       id: "20241202015017",
//       timestamp: "Mon, 02 Dec 2024 01:50:17 +0000",
//       sender_name: "user@example.com",
//       recipient_name: "recipient@example.com",
//       content: "you are not nice.",
//       subject: "User Input",
//       "Non-toxic": 0,
//       toxic: {
//         Impolite: {
//           Sarcasm: 0,
//           Stereotype: 0,
//           "Forced teaming": 1,
//           Authoritarian: 1,
//           Rude: 1,
//           "Trivialising Sensitive topics": 0,
//           "Lack of empathy": 1
//         },
//         "Negative Gossip": {
//           Mocking: 1,
//           Complain: 0
//         },
//         Offensive: {
//           Profanity: 1,
//           Discrimination: 0,
//           Bullying: 1,
//           Violence: 0,
//           Harassment: 0
//         },
//         "Passive-aggressive": 1,
//         Condescending: 0,
//         Microaggression: 1,
//         "Implicit bias": 0,
//         Other: 0
//       },
//       explanation: "The email states, 'you are not nice,' which is a direct accusation that demonstrates rudeness and lack of professionalism...",
//       harmful_phrases: ["you are not nice."],
//       relationship_analysis: {
//         relationship_analysis: {
//           professional_cordial: 0,
//           professional_neutral: 0,
//           professional_toxic: 1,
//           playful_banter: 0,
//           Collegial: 0,
//           Mentor_Mentee: 0,
//           Cross_Functional_Partnerships: 0,
//           Too_personal: 0
//         }
//       },
//       toxicity_analysis: {
//         overall_score: 4,
//         category_scores: {
//           "Impolite: Rude": {
//             score: 4,
//             severity_explanation: "The statement is disrespectful and dismissive, indicating a clear lack of professional courtesy.",
//             evidence: ["you are not nice."],
//             impact_analysis: "This type of communication can foster resentment and diminish morale among team members."
//           }
//         },
//         overall_assessment: {
//           explanation: "This email conveys a rude sentiment without constructive criticism. The direct negative statement damages professional relationships.",
//           organizational_impact: "Such communication may damage workplace relationships and create a hostile work environment."
//         }
//       },
//       reframed_version: {
//         subject: "Feedback on Communication Style",
//         content: "I've noticed that our interactions could be improved. I would appreciate if we could discuss this professionally to find a constructive way forward."
//       }
//     }
//   }
// ];

// Add more sample data entries as needed
// First, create an API service
// const api = {
//     analyze: async (content) => {
//       try {
//         const response = await fetch('/api/analyze', {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//             'X-Local-Storage-ID': localStorage.getItem('user_id')
//           },
//           credentials: 'include', // This is important for cookies
//           body: JSON.stringify({ content })
//         });
//         if (!response.ok) throw new Error('Analysis failed');
//         return response.json();
//       } catch (error) {
//         console.error('Analysis error:', error);
//         throw error;
//       }
//     },

//   submitFeedback: async (emailId, feedbackText) => {
//     try {
//       const response = await fetch('/api/feedback', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           'X-Local-Storage-ID': localStorage.getItem('user_id')
//         },
//         body: JSON.stringify({ email_id: emailId, feedback: feedbackText })
//       });
//       if (!response.ok) throw new Error('Feedback submission failed');
//       return response.json();
//     } catch (error) {
//       console.error('Feedback error:', error);
//       throw error;
//     }
//   },

//   submitReaction: async (analysisId, type) => {
//     try {
//       const response = await fetch('/api/reaction', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           'X-Local-Storage-ID': localStorage.getItem('user_id')
//         },
//         credentials: 'include', 
//         body: JSON.stringify({ analysis_id: analysisId, type })
//       });
//       if (!response.ok) throw new Error('Reaction submission failed');
//       return response.json();
//     } catch (error) {
//       console.error('Reaction error:', error);
//       throw error;
//     }
//   },

//   signup: async (email) => {
//     try {
//       const response = await fetch('/api/signup', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           'X-Local-Storage-ID': localStorage.getItem('user_id')
//         },
//         credentials: 'include',
//         body: JSON.stringify({ email })
//       });
//       if (!response.ok) throw new Error('Signup failed');
//       return response.json();
//     } catch (error) {
//       console.error('Signup error:', error);
//       throw error;
//     }
//   }
// };

export default function EmailAnalyzer() {
  const [email, setEmail] = useState('');
  const [analysis, setAnalysis] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showSignup, setShowSignup] = useState(false);
  const [content, setContent] = useState('');
  const [feedbackUsed, setFeedbackUsed] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [feedbackText, setFeedbackText] = useState('');
  const [error, setError] = useState(null);
  const [signupEmail, setSignupEmail] = useState('');
  const [reactions, setReactions] = useState({ likes: [], dislikes: [] });
  const [signupSuccess, setSignupSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [signupLoading, setSignupLoading] = useState(false);
  
  
  const api = {
    submitSignup: async (email) => {
      const response = await fetch('/api/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Local-Storage-ID': localStorage.getItem('user_id')
        },
        credentials: 'include',
        body: JSON.stringify({ email })
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Signup failed');
      }
      
      return response.json();
    },

    submitReaction: async (analysisId, type) => {
      const response = await fetch('/api/reaction', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Local-Storage-ID': localStorage.getItem('user_id')
        },
        credentials: 'include',
        body: JSON.stringify({ 
          analysis_id: analysisId, 
          type 
        })
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Reaction submission failed');
      }
      
      return response.json();
    },

    submitFeedback: async (emailId, feedbackText, content) => {
      const response = await fetch('/api/feedback', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Local-Storage-ID': localStorage.getItem('user_id')
        },
        credentials: 'include',
        body: JSON.stringify({ 
          email_id: emailId, 
          feedback: feedbackText,
          content: content
        })
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Feedback submission failed');
      }
      
      return response.json();
    }
  };

  // Handler functions using the internal api object
  

  // Initialize reactions when analysis changes
  useEffect(() => {
    if (analysis?.reactions) {
      setReactions(analysis.reactions);
    }
  }, [analysis]);

  // Initialize user ID in localStorage if not present
  useEffect(() => {
    if (!localStorage.getItem('user_id')) {
      localStorage.setItem('user_id', crypto.randomUUID());
    }
  }, []);

  // Handle analysis submission
  // Add analyze function

  // Add this function inside your EmailAnalyzer component
// const loadDummyData = () => {
//   // Simulate loading time
//   setLoading(true);
  
//   // Simulate API delay
//   setTimeout(() => {
//     setAnalysis(DUMMY_ANALYSIS_DATA[0].initial_result);
//     setLoading(false);
//   }, 1000);
// };

// // Update your handleAnalyze function to use dummy data in development
// const handleAnalyze = async () => {
//   if (!content.trim()) {
//     setError('Please enter some content to analyze');
//     return;
//   }

//   setLoading(true);
//   setError(null);

//   // Use dummy data instead of API call
//   try {
//     // Simulate API delay
//     await new Promise(resolve => setTimeout(resolve, 1000));
    
//     // Set the dummy data
//     const dummyResult = {...DUMMY_ANALYSIS_DATA[0].initial_result};
//     // Update the content with user's input
//     dummyResult.content = content;
    
//     setAnalysis(dummyResult);
//   } catch (err) {
//     console.error('Analysis error:', err);
//     setError('Failed to analyze content. Please try again.');
//     setAnalysis(null);
//   } finally {
//     setLoading(false);
//   }
// };

  const handleAnalyze = async () => {
    if (!content.trim()) {
      setError('Please enter some content to analyze');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await fetch('api/analyze', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ content }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setAnalysis(data);
    } catch (err) {
      console.error('Analysis error:', err);
      setError('Failed to analyze content. Please try again.');
      setAnalysis(null);
    } finally {
      setLoading(false);
    }
  };


  // Handle feedback submission
  const handleFeedbackSubmit = async () => {
    if (!feedbackText?.trim() || !analysis?._id) {
      setError('Please enter feedback text');
      return;
    }
  
    setLoading(true);
    setError(null);
  
    try {
      const response = await fetch('/api/feedback', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Local-Storage-ID': localStorage.getItem('user_id')
        },
        credentials: 'include',
        body: JSON.stringify({
          email_id: analysis._id,
          feedback: feedbackText,
          content: content // Add this line to pass the original content
        })
      });
  
      const data = await response.json();
  
      if (!response.ok) {
        throw new Error(data.message || data.error || 'Failed to submit feedback');
      }
  
      setAnalysis(data);
      setFeedbackUsed(true);
      setShowFeedback(false);
      setFeedbackText('');
    } catch (err) {
      console.error('Feedback error:', err);
      setError(err.message || 'Failed to submit feedback. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  // Handle reactions (like/dislike)
  // Update handleReaction function
  const handleReaction = async (type) => {
    if (!analysis?._id) return;

    try {
      // Optimistically update UI
      setReactions(prev => {
        const userId = localStorage.getItem('user_id');
        const oppositeType = type === 'like' ? 'dislikes' : 'likes';
        
        // Remove from opposite type if exists
        const updatedOpposite = prev[oppositeType].filter(id => id !== userId);
        
        // Toggle in current type
        const updatedCurrent = prev[`${type}s`].includes(userId)
          ? prev[`${type}s`].filter(id => id !== userId)
          : [...prev[`${type}s`], userId];
        
        return {
          ...prev,
          [`${type}s`]: updatedCurrent,
          [oppositeType]: updatedOpposite
        };
      });

      await api.submitReaction(analysis._id, type);
      setSuccessMessage(`${type === 'like' ? 'Like' : 'Dislike'} recorded!`);
      setTimeout(() => setSuccessMessage(''), 1500);
    } catch (err) {
      // Revert optimistic update on error
      setError('Failed to record reaction. Please try again.');
      console.error('Failed to record reaction:', err);
    }
  };

  const hasReacted = (type) => {
    const userId = localStorage.getItem('user_id');
    return reactions[`${type}s`].includes(userId);
  };

  const validateEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    
    if (!email) {
      return { isValid: false, error: 'Email is required' };
    }
    
    if (!emailRegex.test(email)) {
      return { 
        isValid: false, 
        error: 'Please enter a valid email address (e.g., example@domain.com)' 
      };
    }
  
    // Check minimum length
    if (email.length < 5) {
      return { 
        isValid: false, 
        error: 'Email address is too short' 
      };
    }
  
    // Check maximum length
    if (email.length > 254) {
      return { 
        isValid: false, 
        error: 'Email address is too long' 
      };
    }
  
    return { isValid: true, error: null };
  };

  // Handle signup
  const handleSignup = async () => {
    const emailValidation = validateEmail(signupEmail);
    
    if (!emailValidation.isValid) {
      setError(emailValidation.error);
      return;
    }
  
    setSignupLoading(true);
    setError(null);
  
    try {
      await api.submitSignup(signupEmail);
      setShowSignup(false);
      setSignupEmail('');
      setSignupSuccess(true);
      setSuccessMessage('Successfully signed up! Thank you for joining.');
      setTimeout(() => {
        setSuccessMessage('');
        setSignupSuccess(false);
      }, 3000);
    } catch (err) {
      setError(err.message || 'Signup failed. Please try again.');
    } finally {
      setSignupLoading(false);
    }
  };
  
  // Add real-time validation as user types (optional)
  const handleEmailChange = (e) => {
    const email = e.target.value;
    setSignupEmail(email);
    
    // Clear previous error when user starts typing again
    if (error) setError(null);
    
    // Optional: Show validation in real-time
    if (email.length > 0) {
      const { isValid, error: validationError } = validateEmail(email);
      if (!isValid) {
        setError(validationError);
      }
    }
  };

  const getScoreColor = (score, isToxic = true) => {
    const scoreNum = parseFloat(score);
    
    if (!isToxic) {
      return {
        bg: 'bg-gradient-to-r from-green-50 to-emerald-50',
        border: 'border-green-200',
        text: 'text-green-700',
        badge: 'bg-green-100'
      };
    }
    
    if (scoreNum >= 4) return {
      bg: 'bg-gradient-to-r from-red-50 to-red-100',
      border: 'border-red-200',
      text: 'text-red-700',
      badge: 'bg-red-100'
    };
    
  if (scoreNum >= 4) return {
    bg: 'bg-gradient-to-r from-red-50 to-red-100',
    border: 'border-red-200',
    text: 'text-red-700',
    badge: 'bg-red-100'
  };
  if (scoreNum >= 3) return {
    bg: 'bg-gradient-to-r from-orange-50 to-orange-100',
    border: 'border-orange-200',
    text: 'text-orange-700',
    badge: 'bg-orange-100'
  };
  return {
    bg: 'bg-gradient-to-r from-yellow-50 to-yellow-100',
    border: 'border-yellow-200',
    text: 'text-yellow-700',
    badge: 'bg-yellow-100'
  };
};

// const CollapsibleSection = ({ title, icon: Icon, gradient, children }) => {
//   const [isExpanded, setIsExpanded] = useState(false);
  
  
//   return (
//     <div className="space-y-4 transition-all duration-300">
//       <div 
//         onClick={() => setIsExpanded(!isExpanded)}
//         className={`p-4 ${gradient} rounded-xl cursor-pointer transition-all duration-300 hover:shadow-md flex justify-between items-center`}
//       >
//         <h3 className="text-lg font-semibold flex items-center gap-2 text-gray-800">
//           <Icon className="w-5 h-5" />
//           {title}
//         </h3>
//         {isExpanded ? 
//           <ChevronDown className="w-5 h-5 text-gray-600" /> : 
//           <ChevronRight className="w-5 h-5 text-gray-600" />
//         }
//       </div>
      
//       <div className={`space-y-4 transition-all duration-300 ${isExpanded ? 'block' : 'hidden'}`}>
//         {children}
//       </div>
//     </div>
//   );
// };

const CollapsibleSection = ({ title, icon: Icon, gradient, children }) => {
  // Set initial state based on title
  const [isExpanded, setIsExpanded] = useState(title === "Detailed Analysis");
  
  return (
    <div className="space-y-4 transition-all duration-300">
      <div 
        onClick={() => setIsExpanded(!isExpanded)}
        className={`p-4 ${gradient} rounded-xl cursor-pointer transition-all duration-300 hover:shadow-md flex justify-between items-center`}
      >
        <h3 className="text-lg font-semibold flex items-center gap-2 text-gray-800">
          <Icon className="w-5 h-5" />
          {title}
        </h3>
        {isExpanded ? 
          <ChevronDown className="w-5 h-5 text-gray-600" /> : 
          <ChevronRight className="w-5 h-5 text-gray-600" />
        }
      </div>
      
      <div className={`space-y-4 transition-all duration-300 ${isExpanded ? 'block' : 'hidden'}`}>
        {children}
      </div>
    </div>
  );
};
// First, create a SubSection component for inner collapsible sections
// const SubSection = ({ title, content, gradient, textColor, borderColor }) => {
//     const [isExpanded, setIsExpanded] = useState(false);
    
//     return (
//       <div className={`${gradient} rounded-xl border ${borderColor} overflow-hidden transition-all duration-200`}>
//         <div 
//           className="p-4 cursor-pointer flex justify-between items-center"
//           onClick={() => setIsExpanded(!isExpanded)}
//         >
//           <h4 className={`font-medium ${textColor}`}>{title}</h4>
//           {isExpanded ? 
//             <ChevronDown className={`w-4 h-4 ${textColor} opacity-70`} /> : 
//             <ChevronRight className={`w-4 h-4 ${textColor} opacity-70`} />
//           }
//         </div>
        
//         {isExpanded && (
//           <div className="px-4 pb-4">
//             <p className="text-gray-700">{content}</p>
//           </div>
//         )}
//       </div>
//     );
//   };

  const SubSection = ({ title, content, gradient, textColor, borderColor, defaultOpen = false }) => {
    const [isExpanded, setIsExpanded] = useState(defaultOpen);
    
    const formatContent = (text) => {
      if (!text) return '';
      
      // First, normalize any existing newlines
      let formattedText = text
        // Add newlines before main points starting with "1." etc
        .replace(/<br\s*\/?>/gi, '\n')
        .replace(/<[^>]+>/g, '\n')

        .replace(/(\d+\.\s*\*\*[^*]+\*\*:)/g, '\n\n$1\n')
        // Convert (a), (b) etc to markdown list items
        .replace(/\(([a-z])\)\s*\*\*/g, '\n   - **')
        // Clean up nested lists
        .replace(/\s*\((?=\d+\.)/g, '\n(')
        // Add newline before any numbered points without bold
        .replace(/(?<![\d\w])\d+\./g, '\n$&')
        // Clean up spaces and newlines
        .split('\n')
        .map(line => line.trim())
        .filter(Boolean)
        .join('\n')
        // Ensure single space after numbers
        .replace(/(\d+\.)\s*/g, '$1 ')
        // Clean up double+ newlines
        .replace(/\n{3,}/g, '\n\n')
        .trim();
    
      return formattedText;
    };

    return (
      <div className={`${gradient} rounded-xl border ${borderColor} overflow-hidden transition-all duration-200`}>
        <div 
          className="p-4 cursor-pointer flex justify-between items-center"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <h4 className={`font-medium ${textColor}`}>{title}</h4>
          {isExpanded ? 
            <ChevronDown className={`w-4 h-4 ${textColor} opacity-70`} /> : 
            <ChevronRight className={`w-4 h-4 ${textColor} opacity-70`} />
          }
        </div>
        
        {isExpanded && (
          <div className="px-4 pb-4">
            <div className="prose prose-sm max-w-none text-gray-700">
              <ReactMarkdown>{formatContent(content)}</ReactMarkdown>
            </div>
          </div>
        )}
      </div>
    );
  };


  const CommunicationPattern = ({ category, data }) => {
    const colors = getScoreColor(data.score);
    const [isExpanded, setIsExpanded] = useState(false);
  
    return (
      <div className={`rounded-xl border ${colors.border} overflow-hidden transition-all duration-200 hover:shadow-md`}>
        <div className={`${colors.bg} p-4 cursor-pointer`} onClick={() => setIsExpanded(!isExpanded)}>
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-4">
              <h3 className={`text-lg font-semibold ${colors.text}`}>{category}</h3>
              <span className={`px-3 py-1 rounded-full text-sm font-medium ${colors.badge} ${colors.text}`}>
                Impact: {data.score}/5
              </span>
            </div>
            {isExpanded ? 
              <ChevronDown className={`w-5 h-5 ${colors.text}`} /> : 
              <ChevronRight className={`w-5 h-5 ${colors.text}`} />
            }
          </div>
          {isExpanded && (
            <div className="mt-4 space-y-4 text-gray-700">
              {data.severity_explanation && (
                <p className="text-base">{data.severity_explanation}</p>
              )}
              
              {data.evidence && data.evidence.length > 0 && (
                <div className="space-y-2">
                  <h4 className="font-medium">Evidence:</h4>
                  <div className="space-y-2">
                    {data.evidence.map((phrase, idx) => (
                      phrase && (
                        <div key={idx} className={`p-2 rounded-lg ${colors.badge} ${colors.text} text-sm flex items-start gap-2`}>
                          <Flag className="w-4 h-4 mt-0.5 flex-shrink-0" />
                          <span className="flex-1">{phrase}</span>
                        </div>
                      )
                    ))}
                  </div>
                </div>
              )}
              
              {data.impact_analysis && (
                <div>
                  <h4 className="font-medium mb-2">Workplace Impact:</h4>
                  <p className="text-sm">{data.impact_analysis}</p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  };
  // Update your JSX to include error messages and new state
  return (
    <div className="h-screen bg-gradient-to-br from-blue-50 via-white to-purple-50 p-0.0125 md:p-0.25 overflow-hidden">

       {/* Header Section - 20vh */}
       <div className="h-[12vh] max-w-7xl mx-auto mb-7">
      <div className="flex items-center justify-center gap-6 px-4">
        {/* Logo with much larger size - using Tailwind's predefined sizes */}
        <div className="flex-shrink-0">
          <Logo className="w-24 h-24 md:w-40 md:h-40" /> 
          {/* w-32 = 8rem (128px), w-40 = 10rem (160px) */}
        </div>
        
        {/* Title and subtitle */}
        <div className="flex flex-col items-center justify-center space-y-2">
          <h1 className="text-2xl md:text-4xl font-bold bg-gradient-to-r from-blue-400 to-blue-500 bg-clip-text text-transparent">
            Workplace Communication Analyzer
          </h1>
          <p className="text-base md:text-lg text-gray-600">
            Here's your chance to analyze whether the messages you've received are toxic!
          </p>
        </div>
      </div>
    </div>
       {/* <div className="h-[10vh] max-w-7xl mx-auto flex flex-col items-center justify-center space-y-2 mb-7">
      <h1 className="text-2xl md:text-4xl font-bold bg-gradient-to-r from-blue-400 to-blue-500 bg-clip-text text-transparent">
        Workplace Communication Analyzer
      </h1>
      <p className="text-base md:text-lg text-gray-600">
       Here's your chance to analyze whether the messages you've received are toxic!
      </p>
    </div> */}

      {/* Error Display */}
      {error && (
        <div className="fixed top-4 right-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded z-50">
          <span className="block sm:inline">{error}</span>
          <button onClick={() => setError(null)} className="absolute top-0 right-0 px-4 py-3">
            <span className="sr-only">Close</span>
            <svg className="h-4 w-4 fill-current" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path d="M14.348 14.849a1 1 0 01-1.414 0L10 11.414l-2.93 2.93a1 1 0 01-1.414-1.414l2.93-2.93-2.93-2.93a1 1 0 011.414-1.414l2.93 2.93 2.93-2.93a1 1 0 011.414 1.414l-2.93 2.93 2.93 2.93a1 1 0 010 1.414z"/>
            </svg>
          </button>
        </div>
      )}

      {/* Clicky tracking code */}
      <div className="fixed bottom-0 left-0 z-50 p-1">
        <a title="Web Analytics" href="https://clicky.com/101471411">
          <img 
            alt="Clicky" 
            src="//static.getclicky.com/media/links/badge.gif" 
            className="border-0 w-1 h-auto"
          />
        </a>
      </div>

      {/* Main content grid */}
      <div className="h-[81vh] max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8">
        {/* Left panel - Input */}
        <div className="h-full bg-white/90 backdrop-blur rounded-2xl shadow-xl overflow-hidden border border-gray-100 flex flex-col">
          <div className="p-6 border-b border-gray-100 bg-gradient-to-r from-gray-50 to-white">
            <h2 className="text-xl font-semibold flex items-center gap-2 text-gray-800">
              <MessageCircle className="w-6 h-6 text-blue-500" />
              Paste Email
            </h2>
          </div>
          
          <div className="flex-1 p-6 flex flex-col">
            <div className="relative flex-1">
              <textarea
                placeholder="Paste the content or email you want to analyze..."
                className="w-full h-full px-4 py-3 rounded-xl border border-blue-200 focus:border-blue-400 focus:ring-2 focus:ring-blue-100 transition-all duration-200 bg-white resize-none"
                value={content}
                onChange={(e) => setContent(e.target.value)}
              />
              <button 
                className="absolute bottom-3 right-3 p-2 text-gray-400 hover:text-blue-500 transition-colors duration-200"
                onClick={() => navigator.clipboard.readText().then(text => setContent(text))}
              >
                <Clipboard className="w-5 h-5" />
              </button>
            </div>

            {/* Action Buttons */}
            <div className="flex flex-wrap gap-4 mt-6">
              <button 
                className="px-6 py-2.5 text-blue-600 bg-blue-50 hover:bg-blue-100 rounded-lg font-medium transition-colors duration-200 flex items-center gap-2"
                onClick={handleAnalyze}
                disabled={loading}
              >
                <Activity className="w-4 h-4" />
                {loading ? 'Analyzing...' : 'Analyze'}
              </button>

              <button 
              onClick={() => setShowSignup(true)}
              className="px-6 py-2.5 text-blue-600 bg-blue-50 hover:bg-blue-100 rounded-lg font-medium transition-colors duration-200 flex items-center gap-2"
            >
              <Users className="w-4 h-4" />
              Join Us
            </button>

            <a 
              href="https://fetchemailanalyzer.onrender.com"
              target="_blank"
              rel="noopener noreferrer"
              className="px-6 py-2.5 text-blue-600 bg-blue-50 hover:bg-blue-100 rounded-lg font-medium transition-colors duration-200 flex items-center gap-2"
            >
              <Inbox className="w-4 h-4" />
              Connect Inbox
            </a>
        
              
              {/* {!feedbackUsed && analysis && (
                <button 
                  onClick={() => setShowFeedback(true)}
                  className="px-6 py-2.5 text-emerald-600 bg-emerald-50 hover:bg-emerald-100 rounded-lg font-medium transition-colors duration-200 flex items-center gap-2"
                >
                  <MessageCircle className="w-4 h-4" />
                  Feedback
                </button>
              )} */}
            </div>
          </div>
        </div>

        {/* Right panel - Analysis Results */}
        {/* // In EmailAnalyzer.jsx, update the right panel section: */}

{/* Right panel - Analysis Results */}
<div className="h-full bg-white/90 backdrop-blur rounded-2xl shadow-xl overflow-hidden border border-gray-100">
  <div className="h-full flex flex-col">
    <div className="p-6 border-b border-gray-100 bg-gradient-to-r from-gray-50 to-white">
      <h2 className="text-xl font-semibold flex items-center gap-2 text-gray-800">
        <Activity className="w-6 h-6 text-blue-500" />
        Communication Analysis
      </h2>
    </div>

    {/* Show this when no analysis is available */}
    {!analysis && !loading && (
      <div className="flex-1 flex flex-col items-center justify-center p-8 text-center">
        <div className="w-24 h-24 mb-6 rounded-full bg-blue-50 flex items-center justify-center">
          <Activity className="w-12 h-12 text-blue-500" />
        </div>
        <h3 className="text-xl font-semibold text-gray-800 mb-2">
          Ready to Analyze
        </h3>
        <p className="text-gray-600 max-w-md">
          Paste your email content in the left panel and click "Analyze" to see detailed insights about your communication.
        </p>
      </div>
    )}

    {/* Show this when analysis is available */}
{/* Show this when analysis is available */}
{analysis && (
  <div className="p-4 space-y-6 max-h-[calc(100vh-200px)] overflow-y-auto">
    {/* Score Display */}
    <div className="grid grid-cols-2 gap-4">
      {/* Toxic/Non-toxic Status */}
      <div className="p-4 bg-gradient-to-r from-opacity-50 to-opacity-25 rounded-xl border transition-all duration-300"
        style={{
          backgroundColor: analysis['Non-toxic'] === 0 ? '#FEF2F2' : '#F0FDF4',
          borderColor: analysis['Non-toxic'] === 0 ? '#FECACA' : '#BBF7D0'
        }}
      >
        <div className="text-center space-y-2">
          <svg 
            className="mx-auto w-6 h-6" 
            fill="none" 
            viewBox="0 0 24 24" 
            stroke="currentColor"
            style={{ 
              color: analysis['Non-toxic'] === 0 ? '#DC2626' : '#16A34A' 
            }}
          >
            {analysis['Non-toxic'] === 0 ? (
              // Warning icon for toxic content
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2} 
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" 
              />
            ) : (
              // Checkmark icon for non-toxic content
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2} 
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            )}
          </svg>
          <div className="text-2xl font-bold" style={{
            color: analysis['Non-toxic'] === 0 ? '#DC2626' : '#16A34A'
          }}>
            {analysis['Non-toxic'] === 0 ? 'Yes' : 'No'}
          </div>
          <div className="text-lg" style={{
            color: analysis['Non-toxic'] === 0 ? '#DC2626' : '#16A34A'
          }}>
            Toxic
          </div>
        </div>
      </div>

      {/* Score Display */}
      <div 
        className="p-4 bg-gradient-to-r rounded-xl border transition-all duration-300"
        style={{
          background: analysis['Non-toxic'] === 0 
            ? 'linear-gradient(to right, #FEF2F2, #FEE2E2)' 
            : 'linear-gradient(to right, #EFF6FF, #DBEAFE)',
          borderColor: analysis['Non-toxic'] === 0 ? '#FECACA' : '#BFDBFE'
        }}
      >
        <div className="text-center">
          <div className="text-4xl font-bold mb-1" style={{
            color: analysis['Non-toxic'] === 0 ? '#DC2626' : '#2563EB'
          }}>
            {analysis['Non-toxic'] === 0 
              ? `${analysis.toxicity_analysis?.overall_score}/10`
              : '9/10'  // or any other score for non-toxic content
            }
          </div>
          <div className="font-medium text-lg" style={{
            color: analysis['Non-toxic'] === 0 ? '#DC2626' : '#2563EB'
          }}>
            {analysis['Non-toxic'] === 0 ? 'Score' : 'Professional Score'}
          </div>
        </div>
      </div>
    </div>



        {/* Toxic Categories Section */}
{analysis['Non-toxic'] === 0 && analysis.toxic && (
  <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
    {Object.entries(analysis.toxic).flatMap(([category, value]) => {
      if (typeof value === 'object') {
        return Object.entries(value)
          .filter(([_, subValue]) => subValue === 1)
          .map(([subCategory]) => (
            <div 
              key={`${category}-${subCategory}`} 
              className="p-3 bg-red-50 rounded-lg border border-red-100 flex items-center gap-2 hover:bg-red-100 transition-colors duration-200"
            >
              <AlertTriangle className="w-4 h-4 text-red-500 flex-shrink-0" />
              <span className="text-red-700 text-sm">
                {`${category}: ${subCategory}`.replace(/_/g, ' ')}
              </span>
            </div>
          ));
      } else if (value === 1) {
        return [(
          <div 
            key={category} 
            className="p-3 bg-red-50 rounded-lg border border-red-100 flex items-center gap-2 hover:bg-red-100 transition-colors duration-200"
          >
            <AlertTriangle className="w-4 h-4 text-red-500 flex-shrink-0" />
            <span className="text-red-700 text-sm">
              {category.replace(/_/g, ' ')}
            </span>
          </div>
        )];
      }
      return [];
    })}
  </div>
)}

        {/* Detailed Analysis Section */}
        <CollapsibleSection 
          title="Detailed Analysis" 
          icon={Zap} 
          gradient="bg-gradient-to-r from-purple-50 to-indigo-50"
        >
          <div className="space-y-3">
            {/* {analysis.explanation && (
              <SubSection 
                title="Communication Style Overview" 
                content={analysis.explanation} 
                gradient="bg-gradient-to-r from-purple-50 to-indigo-50" 
                textColor="text-purple-900" 
                borderColor="border-purple-100" 
                defaultOpen={true}
              />
            )} */}
            
            {(analysis.toxicity_analysis?.overall_assessment?.explanation || 
                analysis.toxicity_analysis?.overall_assessment) && (
                <SubSection 
                    title="Assessment" 
                    content={analysis.toxicity_analysis.overall_assessment?.explanation || 
                            analysis.toxicity_analysis.overall_assessment} 
                    gradient="bg-gradient-to-r from-blue-50 to-indigo-50" 
                    textColor="text-indigo-900" 
                    borderColor="border-indigo-100" 
                    defaultOpen={true}
                />
                )}
            
            {analysis.toxicity_analysis?.overall_assessment?.organizational_impact && (
              <SubSection 
                title="Workplace Impact" 
                content={analysis.toxicity_analysis.overall_assessment.organizational_impact} 
                gradient="bg-gradient-to-r from-indigo-50 to-blue-50" 
                textColor="text-indigo-900" 
                borderColor="border-indigo-100" 
              />
            )}
          </div>
        </CollapsibleSection>

        {/* Communication Patterns Section */}
        {analysis['Non-toxic'] === 0 && (
  <CollapsibleSection 
    title="Toxicity sub-scores" 
    icon={BarChart2} 
    gradient="bg-gradient-to-r from-blue-50 to-indigo-50"
  >
    <div className="space-y-4">
      {analysis.toxicity_analysis?.category_scores && 
        Object.entries(analysis.toxicity_analysis.category_scores)
          .map(([category, data]) => {
            const { impact_analysis, ...dataWithoutImpact } = data;
            return (
              <CommunicationPattern 
                key={category} 
                category={category} 
                data={dataWithoutImpact} 
              />
            );
          })
      }
      
      {(!analysis.toxicity_analysis?.category_scores || 
        Object.keys(analysis.toxicity_analysis?.category_scores).length === 0) && 
        Object.entries(analysis.toxic)
          .filter(([_, value]) => 
            typeof value === 'object' ? 
              Object.values(value).some(v => v === 1) : 
              value === 1
          )
          .map(([category, value]) => {
            const subCategories = typeof value === 'object' ? 
              Object.entries(value)
                .filter(([_, v]) => v === 1)
                .map(([key]) => key) :
              [];
            
            return (
              <CommunicationPattern
                key={category}
                category={category}
                data={{
                  score: 3,
                  evidence: subCategories,
                  severity_explanation: `This communication displays ${category.toLowerCase()} behavior${subCategories.length ? ' through ' + subCategories.join(', ') : ''}.`
                }}
              />
            );
          })
      }
    </div>
  </CollapsibleSection>
)}

        {/* Areas for Improvement Section */}
        {analysis.harmful_phrases && analysis.harmful_phrases.length > 0 && (
          <CollapsibleSection 
            title="Areas for Improvement" 
            icon={AlertCircle} 
            gradient="bg-gradient-to-r from-orange-50 to-red-50"
          >
            <div className="space-y-2">
              {analysis.harmful_phrases.map((phrase, index) => (
                phrase && (
                  <div key={index} className="p-4 bg-gradient-to-r from-orange-50 to-red-50 rounded-xl border border-orange-100 flex items-start gap-3">
                    <AlertTriangle className="w-3 h-3 mt-1.5 text-orange-500 flex-shrink-0" />
                    <span className="text-orange-800 flex-1">{phrase}</span>
                  </div>
                )
              ))}
            </div>
          </CollapsibleSection>
        )}

        {/* Professional Context Section */}
        {/* <CollapsibleSection 
          title="Professional Context" 
          icon={Users}
          gradient="bg-gradient-to-r from-green-50 to-emerald-50"
        >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {Object.entries(analysis.relationship_analysis.relationship_analysis || analysis.relationship_analysis).map(([type, value]) => (
              value === 1 && (
                <div key={type} className="p-4 bg-gradient-to-r from-green-50 to-emerald-50 rounded-xl border border-green-100 flex items-center gap-2">
                  <div className="flex items-center gap-1 text-green-600">
                    <User className="w-3 h-3" />
                    <Check className="w-2.5 h-2.5" />
                  </div>
                  <span className="text-green-800 font-medium">
                    {type.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
                  </span>
                </div>
              )
            ))}
          </div>
        </CollapsibleSection> */}




        {/* Enhanced Version Section */}
        {analysis.reframed_version && (analysis.reframed_version.subject || analysis.reframed_version.content) && (
          <CollapsibleSection 
            title="Reword this email" 
            icon={RefreshCw} 
            gradient="bg-gradient-to-r from-teal-50 to-green-50"
          >
            <div className="space-y-4">
              {analysis.reframed_version.subject && (
                <div className="p-4 bg-gradient-to-r from-teal-50 to-green-50 rounded-xl border border-teal-100">
                  <div className="font-medium text-teal-800 mb-2">Subject:</div>
                  <div className="text-teal-900">{analysis.reframed_version.subject}</div>
                </div>
              )}
              {analysis.reframed_version.content && (
                <div className="p-4 bg-gradient-to-r from-teal-50 to-green-50 rounded-xl border border-teal-100">
                  <div className="font-medium text-teal-800 mb-2">Content:</div>
                  <div className="text-teal-900 whitespace-pre-wrap">
                    {analysis.reframed_version.content}
                  </div>
                </div>
              )}
            </div>
          </CollapsibleSection>
        )}
      </div>
    )}

    {/* Loading State */}
    {loading && (
      <div className="flex-1 flex flex-col items-center justify-center p-8">
        <div className="w-16 h-16 mb-4">
          <Activity className="w-full h-full text-blue-500 animate-spin" />
        </div>
        <p className="text-gray-600">Analyzing communication...</p>
      </div>
    )}
  </div>
</div>
</div>

          
     
      {/* Feedback Dialog */}
      {/* Feedback Dialog */}
{showFeedback && (
  <div className="fixed inset-0 bg-black/50 flex items-center justify-center backdrop-blur-sm z-50 p-4">
    <div className="bg-white rounded-2xl p-6 md:p-8 max-w-md w-full mx-auto transform transition-all duration-300 scale-100">
      <div className="space-y-4">
        <h3 className="text-xl md:text-2xl font-bold text-gray-900">Provide Your Feedback</h3>
        <p className="text-gray-600">Help us improve our analysis by sharing your thoughts</p>
        
        <textarea
          value={feedbackText}
          onChange={(e) => setFeedbackText(e.target.value)}
          placeholder="Enter your feedback here..."
          className="w-full h-32 px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200 resize-none"
        />
        
        <div className="flex flex-col gap-3">
          <button 
            onClick={() => handleFeedbackSubmit(feedbackText, analysis._id)}  // Updated this line
            disabled={loading || !feedbackText.trim()}
            className="w-full px-6 py-3 bg-gradient-to-r from-blue-500 to-indigo-600 text-white rounded-lg font-medium shadow-lg hover:shadow-xl transform hover:-translate-y-0.5 transition-all duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {loading ? 'Submitting...' : 'Submit Feedback'}
          </button>
          
          <button 
            onClick={() => {
              setShowFeedback(false);
              setFeedbackText('');
            }}
            className="text-gray-500 hover:text-gray-700 font-medium transition-colors duration-200"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
)}

      {/* Signup Dialog */}
      {showSignup && (
             <div className="fixed inset-0 bg-black/50 flex items-center justify-center backdrop-blur-sm z-50 p-4">
            <div className="bg-white rounded-2xl p-6 md:p-8 max-w-md w-full mx-auto transform transition-all duration-300 scale-100">
            <div className="text-center space-y-4">
                <h3 className="text-xl md:text-2xl font-bold text-gray-900">Join Our Waitlist</h3>
                <p className="text-gray-600">Empower yourself to create healthier workplace communication, one email at a time.</p>
                
                <div className="space-y-4 mt-6">
                <div className="space-y-2">
                    <input 
                    type="email" 
                    value={signupEmail}
                    onChange={handleEmailChange}
                    placeholder="Enter your email"
                    className={`w-full px-4 py-3 border rounded-lg transition-all duration-200
                        ${error ? 
                        'border-red-300 focus:border-red-500 focus:ring-red-200' : 
                        'border-gray-300 focus:border-blue-500 focus:ring-blue-200'
                        }
                        focus:ring-2 focus:outline-none`}
                    />
                    {error && (
                    <p className="text-sm text-red-600 mt-1 flex items-center">
                        <AlertCircle className="w-4 h-4 mr-1" />
                        {error}
                    </p>
                    )}
                </div>
                
                <button 
                    onClick={handleSignup}
                    disabled={signupLoading || error || !signupEmail}
                    className={`w-full px-6 py-3 rounded-lg font-medium shadow-lg 
                    hover:shadow-xl transform hover:-translate-y-0.5 transition-all 
                    duration-200 ${
                        error || !signupEmail
                        ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                        : 'bg-gradient-to-r from-blue-500 to-indigo-600 text-white'
                    }`}
                >
                    {signupLoading ? 'Signing up...' : 'Sign Up'}
                </button>
                
                <button 
                    onClick={() => {
                    setShowSignup(false);
                    setSignupEmail('');
                    setError(null);
                    }}
                    className="text-gray-500 hover:text-gray-700 font-medium transition-colors duration-200"
                >
                    Maybe Later
                </button>
                </div>
            </div>
            </div>
        </div>
        )}
      {/* Floating action buttons */}
      <div className="fixed flex flex-col gap-3" style={{ 
        right: 'calc(1rem)',
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: 50
      }}>
        <button 
          onClick={() => setShowSignup(true)}
          className={`p-2.5 bg-white border rounded-full shadow-sm hover:shadow-md transition-all duration-200 ${
            signupSuccess 
              ? 'text-green-500 border-green-200 hover:border-green-400' 
              : 'text-blue-500 border-blue-200 hover:border-blue-400'
          }`}
        >
          <Users className="w-4 h-4 md:w-5 md:h-5" />
        </button>
        
        <button 
          onClick={() => window.print()}
          className="p-2.5 bg-white text-gray-500 border border-gray-200 rounded-full shadow-sm hover:shadow-md hover:border-gray-400 transition-all duration-200"
        >
          <Printer className="w-4 h-4 md:w-5 md:h-5" />
        </button>
        
        {analysis && (
          <>
            <button 
              onClick={() => handleReaction('like')}
              className={`p-2.5 bg-white border rounded-full shadow-sm hover:shadow-md transition-all duration-200 ${
                hasReacted('like')
                  ? 'text-green-500 border-green-200 hover:border-green-400'
                  : 'text-gray-500 border-gray-200 hover:border-gray-400'
              }`}
            >
              <ThumbsUp className={`w-4 h-4 md:w-5 md:h-5 ${
                hasReacted('like') ? 'fill-current' : ''
              }`} />
            </button>
            <button 
              onClick={() => handleReaction('dislike')}
              className={`p-2.5 bg-white border rounded-full shadow-sm hover:shadow-md transition-all duration-200 ${
                hasReacted('dislike')
                  ? 'text-red-500 border-red-200 hover:border-red-400'
                  : 'text-gray-500 border-gray-200 hover:border-gray-400'
              }`}
            >
              <ThumbsDown className={`w-4 h-4 md:w-5 md:h-5 ${
                hasReacted('dislike') ? 'fill-current' : ''
              }`} />
            </button>
          </>
        )}
      </div>

      {/* Success Message Toast */}
      {successMessage && (
        <div className="fixed bottom-4 right-4 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded z-50 animate-fade-in-up">
          <span className="block sm:inline">{successMessage}</span>
        </div>
      )}

      {/* Loading Overlay */}
      {loading && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center backdrop-blur-sm z-50">
          <div className="bg-white rounded-xl p-6 md:p-8 flex flex-col items-center">
            <Activity className="w-6 h-6 md:w-8 md:h-8 text-blue-500 animate-spin" />
            <p className="mt-4 text-gray-700">Analyzing communication...</p>
          </div>
        </div>
      )}
    </div>
  );
}