import { AnalysisProvider } from './contexts/AnalysisContext';
import EmailAnalyzer from './components/EmailAnalyzer';

function App() {
  return (
    <AnalysisProvider>
      <EmailAnalyzer />
    </AnalysisProvider>
  );
}

export default App;