import { createContext, useContext, useState, useCallback } from 'react';
import { apiService } from '../services/api';

const AnalysisContext = createContext(null);

export function AnalysisProvider({ children }) {
  const [analysis, setAnalysis] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const performAnalysis = useCallback(async (content) => {
    setLoading(true);
    setError(null);
    try {
      const result = await apiService.analyze(content);
      setAnalysis(result);
      return result;
    } catch (err) {
      setError(err.message);
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  const submitFeedback = useCallback(async (emailId, feedback) => {
    setLoading(true);
    setError(null);
    try {
      const result = await apiService.submitFeedback(emailId, feedback);
      setAnalysis(result);
      return result;
    } catch (err) {
      setError(err.message);
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  const submitReaction = useCallback(async (analysisId, type) => {
    try {
      await apiService.submitReaction(analysisId, type);
    } catch (err) {
      console.error('Failed to submit reaction:', err);
    }
  }, []);

  return (
    <AnalysisContext.Provider value={{
      analysis,
      loading,
      error,
      performAnalysis,
      submitFeedback,
      submitReaction,
      setError,
    }}>
      {children}
    </AnalysisContext.Provider>
  );
}

export function useAnalysis() {
  const context = useContext(AnalysisContext);
  if (!context) {
    throw new Error('useAnalysis must be used within an AnalysisProvider');
  }
  return context;
}