class ApiService {
    constructor() {
      this.baseUrl = '/api';
    }
  
    async makeRequest(endpoint, method = 'GET', data = null) {
      const headers = {
        'Content-Type': 'application/json',
        'X-Local-Storage-ID': localStorage.getItem('user_id')
      };
  
      const config = {
        method,
        headers,
        credentials: 'include', // This is important for cookies
      };
  
      if (data) {
        config.body = JSON.stringify(data);
      }
  
      try {
        const response = await fetch(`${this.baseUrl}${endpoint}`, config);
        
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Request failed');
        }
  
        return response.json();
      } catch (error) {
        console.error(`API Error (${endpoint}):`, error);
        throw error;
      }
    }
  
    // Analysis endpoints
    analyze(content) {
      return this.makeRequest('/analyze', 'POST', { content });
    }
  
    submitFeedback(emailId, feedback) {
      return this.makeRequest('/feedback', 'POST', { email_id: emailId, feedback });
    }
  
    submitReaction(analysisId, type) {
      return this.makeRequest('/reaction', 'POST', { analysis_id: analysisId, type });
    }
  
    signup(email) {
      return this.makeRequest('/signup', 'POST', { email });
    }
  }
  
  export const apiService = new ApiService();